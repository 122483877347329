<template>
  <div></div>
</template>

<script>
import { getCertifyId, getVerify } from "../http/request";
import { getQueryString } from "../utils/index";
export default {
  props: {},
  data() {
    return {
      urlData: "",
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.urlData = decodeURIComponent(getQueryString("response"));
    this.realAuth();
  },
  watch: {
    urlData(val) {
      if (val != "" && val != null && val != "null") {
        this.getVerify(JSON.parse(val).extInfo.certifyId);
      }
    },
  },
  methods: {
    // 真人认证
    async realAuth() {
      let metaInfo = getMetaInfo();
      let data = await getCertifyId(metaInfo);
      if (data.appCode == "S0000") {
        window.location.href = data.result;
      }
    },
    async getVerify(id) {
      let data = await getVerify(id);
      if (data.appCode == "S0000") {
        let res = data.result.passed;
        if (res == "T") {
          console.log("通过了");
          callFlutter.postMessage(true);
        } else {
          console.log("没通过");
          callFlutter.postMessage(false);
        }
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
</style>